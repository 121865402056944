import { createAction, props } from '@ngrx/store';
import { Event, Lounge } from 'src/app/core/models/app.models';
import { PackageDetails } from 'src/app/features/package/models/package-details.interface';

export const updateReservationClicked = createAction(
  '[Edit Lounge Container] Update Reservation Clicked',
  props<{
    id: number;
  }>(),
);

export const editLoungeClicked = createAction(
  '[Edit Lounge Container] Edit Lounge Clicked',
);
export const loungeInitialized = createAction(
  '[Edit Lounge Container] Lounge Initialized',
  props<{
    lounge: Lounge;
  }>(),
);

export const backClicked = createAction(
  '[Edit Lounge Container] Back Clicked',
  props<{
    event: Event;
  }>(),
);

export const saveReservationClicked = createAction(
  '[Edit Lounge Container] Save Reservation Clicked',
);

export const packagesLoaded = createAction(
  '[Edit Lounge Container] Packages loaded',
  props<{
    packages: PackageDetails[];
  }>(),
);

export const selectPackage = createAction(
  '[Edit Lounge Container] Select package',
  props<{
    packageId: number;
  }>(),
);

export const deselectPackage = createAction(
  '[Edit Lounge Container] Deselect package',
  props<{
    packageId: number;
  }>(),
);

export const selectAllPackages = createAction(
  '[Edit Lounge Container] Select all packages',
  props<{
    selectAll: boolean;
  }>(),
);

export const downloadPdf = createAction(
  '[Edit Lounge Container] Download PDF',
  props<{
    reservationId: number;
  }>(),
);

export const cancelReservation = createAction(
  '[Edit Lounge Container] Cancel Reservation',
  props<{
    reservationId: number;
  }>(),
);
