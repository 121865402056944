import * as EditLoungeContainerActions from './actions/edit-lounge-container.actions';
import * as EditLoungeEffectsActions from './actions/edit-lounge-effects.actions';
import { editLoungeFeature } from './edit-lounge.reducers';

export * as editLoungeState from './edit-lounge.state';

const { reducer, name, ...editLoungeSelectors } = editLoungeFeature;

export {
  reducer as editLoungeReducer,
  name as editLoungeName,
  editLoungeSelectors,
  EditLoungeContainerActions,
  EditLoungeEffectsActions,
};
