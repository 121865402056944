import { createFeature, createReducer, on } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { MyEventsContainerActions } from '../../../core/store/actions';
import { OrbitContainerActions } from '../../../core/store/actions/index';
import { EditLoungeState } from './edit-lounge.state';
import { EditLoungeContainerActions } from './index';
import { mapEventToEventInformation } from '../../../core/helpers/mappers';

export const initialState: Readonly<EditLoungeState> = {
  lounge: {
    id: 0,
    name: '',
    comment: '',
    loungeType: null,
    numberSeats: 0,
    event: null,
    reservation: null,
    loungeStatus: 'available',
    packages: [],
    locationId: 0,
    location: null,
  },
  eventInformation: {
    id: 0,
    name: '',
    ageRestrictionWomen: '',
    ageRestrictionMen: '',
    ticketLink: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: '',
    startTime: '',
    endTime: '',
    fromPrice: '',
    internalLabel: -1,
    pressText: '',
    fyraTicketLink: '',
    location: null,
    locationName: '',
    locationStreet: '',
    locationPlz: '',
    locationProvince: '',
    genres: [],
    tags: [],
    flyer: null,
  },
  packages: [],
  selectedPackages: [],
};

export const editLoungeReducers = createReducer(
  initialState,
  on(MyEventsContainerActions.editEventClicked, (state, { event }) => ({
    ...state,
    currentStep: 1,
    eventInformation: mapEventToEventInformation(event),
  })),
  on(MyEventsContainerActions.editEventClicked, (state, { event }) => ({
    ...state,
    eventInformation: mapEventToEventInformation(event),
  })),
  on(OrbitContainerActions.editLoungeClicked, (state, { lounge }) => ({
    ...state,
    lounge: {
      ...lounge,
    },
    eventInformation: lounge!.event!,
  })),
  on(EditLoungeContainerActions.editLoungeClicked, (state) => ({
    ...state,
    lounge: state.lounge,
    eventInformation: state.lounge!.event!,
  })),
  on(EditLoungeContainerActions.packagesLoaded, (state, { packages }) => ({
    ...state,
    packages: packages,
  })),

  on(EditLoungeContainerActions.selectPackage, (state, { packageId }) => ({
    ...state,
    selectedPackages: [...state.selectedPackages, packageId],
  })),
  on(EditLoungeContainerActions.deselectPackage, (state, { packageId }) => ({
    ...state,
    selectedPackages: state.selectedPackages.filter((id) => id !== packageId),
  })),
  on(EditLoungeContainerActions.selectAllPackages, (state, { selectAll }) => ({
    ...state,
    selectedPackages: selectAll ? state.packages.map((p) => p.id) : [],
  })),
);

export const editLoungeFeature = createFeature({
  name: 'editLounge',
  reducer: editLoungeReducers,
});
