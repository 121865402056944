import { createAction } from '@ngrx/store';

export const formValid = createAction('[Edit Lounge Effects] Form Valid');
export const formInvalid = createAction('[Edit Lounge Effects] Form Invalid');
export const editLoungeSuccessful = createAction(
  '[Edit Lounge Effects] Edit Lounge Successful',
);
export const editLoungeUnsuccessful = createAction(
  '[Edit Lounge Effects] Edit Lounge Unsuccessful',
);
export const editLoungeFormInvalid = createAction(
  '[Edit Lounge Effects] Edit Lounge Form Invalid',
);
export const editLoungeStarted = createAction(
  '[Edit Lounge Effects] Edit Lounge Started',
);
export const editListPostponed = createAction(
  '[Edit Lounge Effects] Edit Lounge Postponed',
);
export const saveReservationSuccessful = createAction(
  '[Edit Lounge Effects] Save Reservation Successful',
);
export const saveReservationUnsuccessful = createAction(
  '[Edit Lounge Effects] Save Reservation Unsuccessful',
);
export const cancelReservationSuccessful = createAction(
  '[Edit Lounge Effects] Cancel Reservation Successful',
);
export const cancelReservationUnsuccessful = createAction(
  '[Edit Lounge Effects] Cancel Reservation Unsuccessful',
);
export const guestsOverLoungeCapacity = createAction(
  '[Edit Lounge Effects] Guests Number over Lounge Capacity',
);
export const updateReservationUnsuccessful = createAction(
  '[Edit Lounge Effects] Update Reservation Unsuccessful',
);
export const updateReservationSuccessful = createAction(
  '[Edit Lounge Effects] Update Reservation Successful',
);
